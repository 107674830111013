export default {
  enable: "Activar",
  disable: "Desactivar",
  name: "Nombre",
  lastName: "Apellido",
  contactName: "Nombre de contacto",
  commercialName: "Nombre comercial",
  phone: "Telefono",
  population: "Población",
  province: "Provincia",
  hour: "hora",
  save: "Guardar",
  delete: "Eliminar",
  observations: "Observaciones",
  country: "País",
  postalCode: "Codigo Postal",
  client: "Cliente",
  passport: "N.I.F / C.I.F / Pasaporte",
  email: "Email",
  suscribe: "Suscribirse",
  message: "Mensaje",
  loading: "Cargando...",
  update: "Actualizar",
  date: "Fecha",
  invalidOrderFields: "Hay campos invalidos en su pedido",
  brands: "Marcas",
  total: "Total",
  showAll: "Mostrar todos",
  invoice: "Factura",
  identifier: "Identificador",
  description: "Descripción",
  keepShoping: "Continuar comprando",
  goCheckout: "Tramitar pedido",
  product: "Producto",
  products: "Productos",
  category: "Categoría",
  categories: "Categorías",
  productCategories: "Categorías de productos",
  barCode: "Codigo de barras",
  barCodeHeader: "Cod. Barras",
  price: "Precio",
  password: "Contraseña",
  recoverPassword: "Recuperar contraseña",
  quantity: "Cantidad",
  stock: "Stock",
  action: "Acción",
  search: "Buscar",
  findByDate: "Filtrar por fecha",
  searching: "Buscando...",
  address: "Dirección",
  addProduct: "Añadir Producto",
  addresses: "Direcciones",
  activate: "Activar",
  deactivate: "Desactivar",
  close: "Cerrar",
  warning: "Aviso",
  create: "Crear",
  back: "Volver",
  next: "Siguiente",
  continue: "Continuar",
  download: "Descargar",
  reference: "Referencia",
  referenceHeader:"Ref",
  welcome: "Bienvenido",
  cancel: "Cancelar",
  accept: "Aceptar",
  send: "Enviar",
  end: "Terminar",
  notAvaliableServiceHeader: "¡Lo sentimos!",
  notAvaliableServiceBody: "Este servicio aún no esta disponible.",
  noResults: "No hay resultados.",
  emptyLabel: "Por favor, rellene este campo",
  invalidPostalCode: "Codigo postal invalido",
  minimumLength: "El campo debe tener al menos %numberSlot% caracteres.",
  maximusLength: "El campo debe tener maximo %numberSlot% caracteres.",
  characters: "Caracteres",
  clone: "Clonar",
  invalidEmail: "Email invalido",
  requiredEmail: "Email requerido",
  requiredInput: "Campo requerido",
  requiredPassword: "Contraseña requerida",
  invalidDyn_1: "El campo",
  invalidDyn_2: "es invalido.",
  minimunSearch_1: "Su busqueda debería contener como minimo 3 letras",
  minimunSearch_2: "letras.",
  notMatchingPasswords: "Las contraseñas ingresadas no coinciden.",
  deleteAddressConfirmation: "¿Está seguro de eliminar esta Dirección?",
  createAddressConfirmation: "¿Está seguro de crear esta Dirección?",
  activateClientConfirmation: "¿Desea usted activar este usuario?",
  deactivateClientConfirmation: "¿Desea usted desactivar este usuario?",
  createClientConfirmation: "¿Está seguro de crear el cliente?",
  clearCartConfirmation: "¿Esta seguro de limpiar el carrito?",
  orderConfirmation: "¿Desea usted crear este pedido?",
  patchOrderConfirmation: "¿Esta seguro de querer editar su pedido? Los cambios serán irreversibles una ves el pedido haya sido enviado.",
  deleteOrderConfirmation: "Si continua, su pedido será eliminado.",
  updatePasswordConfirmation: "¿Está seguro de actualizar su contraseña?",
  politicConfirmation: "He leído y acepto la política de privacidad.",
  home: "Inicio",
  novelties: "Novedades",
  videos: "Videos",
  contact: "Contacto",
  company: "Empresa",
  personalData: "Datos Personales",
  order: "Pedido",
  orders: "Pedidos",
  createOrder: "Crear pedido",
  invoices: "Facturas",
  promo: "Promociones",
  notifications: "Notificaciones",
  favourites: "Favoritos",
  clients: "Clientes",
  login: "Iniciar Sesion",
  logout: "Cerrar sesion",
  nothing: "Ninguna",
  nothingToShow: "Nada que mostrar",
  myProfile: "Mi perfil",
  replacePassword: "Reemplazar mi Contraseña",
  replacePasswordLabel_1: "Clave de acceso actual",
  replacePasswordLabel_2: "Nueva clave de acceso",
  replacePasswordLabel_3: "Repetir nueva clave",
  advertisement: "Suscribete a nuestro newsletter y mantente informado de todas nuestras novedades y últimos lanzamientos.",
  advertisementCheckBox: "He leído y acepto la política",
  inProgressStatus: "En progreso",
  waitingStatus: "En Espera",
  sendStatus: "Enviado",
  finishStatus: "Finalizado",
  draftStatus: "Borrador",
  canceledStatus: "Cancelado",
  retrasedStatus: "Retrasado",
  orderRefecence: "Referencia del Pedido",
  orderReferenceHeader: "Ref. Pedido",
  buyDateAtHeader: "Fecha de compra",
  stateHeader: "Estado",
  invoiceNumber: "Numero de Factura",
  invoiceNumberHeader:"N° Factura",
  notFoundInvoices: "No se han encontrado facturas.",
  newClient: "Nuevo cliente",
  createClient: "Crear cliente",
  deactivatedClients: "Clientes desactivados",
  noAvaliableClients: "No hay clientes disponibles",
  clientInfo: "Información del cliente",
  clientFile: "Ficha de cliente",
  createAddress: "Crear dirección de envío",
  newAddress: "Nueva Dirección",
  newDeliveryAddress: "Nueva dirección de envío",
  sendAddress: "Dirección de Envío",
  logoutWarning: "Si continua, finalizará su sesión.",
  searchTip_1: "¿Descontento con los resultados?",
  searchTip_2: "Busca con una nueva palabra",
  notFoundProducts: "No se han encontrado productos.",
  estimatedStockDate: "Fecha aproximada de disponibilidad",
  boxQuantity: "Unidades por caja",
  minimumBuyQuantity: "Unidades minimas de venta",
  downloadFileProduct: "Descargar ficha del producto",
  noAvaliableProducts: "No hay productos disponibles.",
  expandCart: "Pagar",
  emptyCartMessage: "No hay productos en el carrito.",
  footerLeftInfo: "¿Preguntas? El equipo Perfect esta aquí para ayudar.",
  footerRightInfo: "Si tienes cualquier duda o consulta técnica sobre alguno de nuestros productos escríbenos y nuestro técnico o comercial se pondrá en contacto contigo.",
  legalWarn: "Aviso legal",
  privacityPolitics: "Política de privacidad",
  cookiesPolitics: "Política de cookies",
  checkout: "Tramitar pedido",
  deliveryAddress: "Dirección de entrega",
  orderSummary: "Resumen del pedido",
  backCart: "Volver al carrito",
  noAvaliableOrders: "No hay ordenes disponibles",
  taxBase: "Base imponible",
  taxBaseHeader: "B. imponible",
  pvpTaxBaseHeader: "B. imponible PVP",
  commerciaRate: "P.V.P",
  noProductsInCart: "Debe añadir por lo menos un producto para continuar",
  downloadHere: "Descarga aquí",
  slogan_1: "Perfect Beauty es sinonimo de...",
  slogan_2: "\"diseño, innovación, tecnología y pasión\"",
  intro_1: "En Perfect Beauty seguimos evolucionando e invirtiendo para lograr el producto de mayor calidad, vanguardia y tecnologia, para ello hemos apostado por la creacion de un equipo de I+D con el fin de legar al máximo de calida, eficacia, y operatividad en cada una de las novedades que sacamos al mercado. Otro de los pilares en Perfect Beauty es el Control de Calidad, por el cual pasan cada uno de nuestros productos y piezas que lo componen aportándonos una fiabilidad hacia nuestros clientes superior a cualquier marca de eléctricos. Con nuestro Servicio Técnico aseguramos cumplir todas las garantías.",
  intro_2: "En definitiva un grupo de personas destinadas a responder, informar y solucionar cualquier duda o sugerencia de nuestros clientes.",
  newsletter: "Suscríbete a nuestro newsletter y mantente informado de todas nuestras novedades y últimos lanzamientos.",
  watchMap: "Ver mapa",
  cataloguePDF: "Catálogo PDF",
  companyIntro_1: "Productos utilizados por profesionales de los más destacados salones de belleza y peluquería. Seguimos ampliando las posibilidades y abriendo horizontes para los profesionales de la peluquería y la estética. Siempre actualizados, con lo último y más innovador para cubrir cualquier necesidad del sector.",
  companyIntro_2: "Perfect Beauty SL, una empresa fundada por tres profesionales con más de 30 años de experiencia en el sector de peluquería y estética con la misión de proporcionar al canal profesional productos de calidad e innovadores. Una compañía enfocada a dar el mejor servicio a un precio competitivo. Para ello contamos con un equipo humano de 25 profesionales, distribuidos en distintos departamentos; atención al cliente, ventas, control de calidad, servicio técnico, I+D+I., marketing y administración, con 600 m2 de oficinas, y un almacén de 7000 m2 de superficie.",
  companyIntro_3: "Nuestro objetivo es proporcionar a los profesionales una amplia variedad de productos para facilitar el trabajo en los sectores de peluquería, barbería y estética. Ponemos a disposición de nuestros clientes un catálogo formado por más de 3.000 referencias, siendo este actualizado 2 veces al año con el fin de ser fieles a nuestra misión de empresa de estar a la vanguardia del sector con productos innovadores.",
  companyIntro_4: "El control de calidad como elemento diferenciador, nos hace ser un referente en el mercado. Gracias a los recursos destinados por la empresa para el control del producto tanto en origen como en destino nos postula como empresa de garantía. Nuestro servicio post venta se suma a este compromiso de clientes satisfechos. Un departamento propio de investigación y desarrollo nos garantiza ser innovadores en diseños, componentes y nuevas utilidades de producto. Un equipo dedicado a mantener la marca Perfect Beauty como una empresa dinámica que aporte soluciones al profesional de la Peluquería, la Barbería y la Estética.",
  companyIntro_5: "La dirección de Perfect Beauty es consciente de la relevancia que tiene la contribución de todos, empresas, instituciones, administraciones públicas y particulares, en el fomento de prácticas responsables que respeten el medio ambiente y que permitan un claro avance de la sociedad hacia un mundo mejor, es por este motivo que se ha impuesto la integración de unas directrices de carácter ambiental en su estrategia de negocio. Estas directrices se plasman en la Política medioambiental que se inspira en dos principios: cumplimiento de toda la normativa medioambiental aplicable y la mejora continua de las actividades con el fin de proteger el medioambiente. Con ambos principios se compromete a la prevención, protección y conservación del medio ambiente.",
  companyIntro_6: "Para llevar a cabo estos principios la dirección de Perfect Beauty se propone: - Adoptar medidas necesarias para prevenir la contaminación, y cuando ello no sea posible, reducir al mínimo las emisiones contaminantes. - Reducir sistemáticamente los residuos, reciclándolos y reutilizándolos siempre que sea posible, así como utilizar de manera eficiente los recursos naturales, materias primas y energía. - Aplicar las medidas necesarias para garantizar el cumplimiento de la normativa medioambiental aplicable a nuestras actividades en el ámbito local, nacional y global en el que la empresa actúa. - Difundir la política medio ambiental entre los empleados, proveedores, y otras partes interesadas. - Formar y concienciar a empleados para que en su día a día de trabajo realicen su desempeño siguiendo la política medio ambiental. - Establecer procedimientos para la revisión periódica del cumplimiento de la política medioambiental, así como aplicar medidas correctoras cuando estas hayan sido incumplidas. - Realizar un proceso de mejora continua de nuestra actuación con respecto al medio ambiente.",
  corporativeVideo: "Video corporativo.",
  noAddedProducts: "No hay productos para comprar.",
  favouriteAdded: "El producto ha sido marcado como favorito.",
  username: "Nombre de Usuario",
  dear: "Estimado",
  orderSuccesfullySended: "Su pedido ha sido enviado exitosamente.",
  orderSendedMessageQuery_1: "Recibirá en su email un resumen de su pedido",
  thanks: "Muchas gracias.",
  commercialDepartament: "Departamento comercial de Perfect Beauty.",
  seeOrders: "Ver pedidos",
  clearFilters: "Limpiar filtros",
  inStock: "En Stock",
  noStock: "Sin Stock",
  filterByProduct: "Filtar por producto",
  filterByDate: "Filtrar por fecha",
  filterByStatus: "Filtrar por estado",
  filterByClient: "Filtrar por cliente",
  fiscalName: "Nombre fiscal",

  errorUnlogging: "Ha ocurrido un error mientras se intentaba cerrar la sesión.",
  successLogin: "Sesión iniciada exitosamente.",
  myCompany: "Mi Empresa",
  goHeadquarterCheckout: "Tramitar pedido a Sede",
  headquarter: "Sede",
  headquarters: "Sedes",
}