import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("Video")
export default class Video {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("url", String, true)
    url?: string = undefined
    @JsonProperty("displays", Number, true)
    displays?: number = undefined

}
