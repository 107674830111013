import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";

export enum AuthorityName {
    SUPER_ADMIN = "SUPER_ADMIN",
    ADMIN = "ADMIN",
    DISTRIBUTOR = "DISTRIBUTOR",
    COMMERCIAL = "COMMERCIAL",
    MANAGER = "MANAGER",
    USER = "USER",
    SWAGGER = "SWAGGER",
    HEADQUARTER_MANAGER = "HEADQUARTER_MANAGER",
}

@JsonConverter
class NameConverter implements JsonCustomConvert<AuthorityName> {

    deserialize(data: string): AuthorityName {
        return (<any>AuthorityName)[data]
    }
    serialize(data: AuthorityName): any {
        return data.toString()
    }

}

@JsonObject("Authority")
export default class Authority {

    @JsonProperty("role", NameConverter, true)
    name?: AuthorityName = undefined
    @JsonProperty("title", String, true)
    title?: string = undefined
    @JsonProperty("description", String, true)
    description?: string = undefined
    @JsonProperty("enabled", Boolean, true)
    enabled?: boolean = undefined

}

