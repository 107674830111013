












import {Component, Prop, Ref, Vue} from "vue-property-decorator"
import Client from "@/models/Client"
import Address from "@/models/Address"
import {getModule} from "vuex-module-decorators"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import ClientService from "@/services/ClientService"
import AddressService from "@/services/AddressService"
import StringTool from "@/services/tool/StringTool"
import SnackbarModule from "@/store/SnackbarModule"
import LangModule from "@/store/LangModule"

@Component
export default class SplashComponent extends Vue {

	@Prop() readonly overlay!: boolean

}
