




















import {Component, Vue} from "vue-property-decorator";
import DialogModule from "@/store/DialogModule";
import {getModule} from "vuex-module-decorators";

@Component
export default class DialogComponent extends Vue {

  dialogModule: DialogModule = getModule(DialogModule)
  get dialog() { return this.dialogModule.enabled }

  set dialog(enabled: boolean) {
    this.dialogModule.setDialogEnabled(enabled)
  }

  acceptClick() {
    this.dialogModule.setDialogEnabled(false)
    this.dialogModule.callback()
  }
}
