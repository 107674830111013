

















import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import ProductCategory from "@/models/ProductCategory"
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule"

@Component
export default class CategoriesTreeviewComponent extends Vue {

	lang: any = getModule(LangModule).lang
	categoryTreeviewModule = getModule(CategoryTreeviewModule)

	@Prop() readonly productCategories!: ProductCategory[]
	@Prop() readonly loading!: boolean

	get selectedCategories() { return this.categoryTreeviewModule.selectedCategories }
	set selectedCategories(categoryIds: number[]) { this.categoryTreeviewModule.setSelectedCategories(categoryIds) }

	get titleMaxLenghtByScreenSize() {

		let maxLength = 20
		let screen = this.$vuetify.breakpoint.name

		switch (screen) {
			case "xl" : { maxLength = 35; break;}
			case "lg" : { maxLength = 20; break;}
			case "md" : { maxLength = 20; break;}
		}

		return maxLength
	}

}
