

















import {Component, Mixins, Vue} from 'vue-property-decorator'
import HeaderComponent from "@/components/HeaderComponent.vue"
import FooterComponent from "@/components/FooterComponent.vue"
import SnackbarComponent from "@/components/SnackbarComponent.vue"
import DrawerComponent from "@/components/DrawerComponent.vue"
import DialogComponent from "@/components/DialogComponent.vue"
import LoadingComponent from "@/components/SplashComponent.vue"
import DesktopHeaderComponent from "@/components/appbars/DesktopHeaderComponent.vue"
import MobileHeaderComponent from "@/components/appbars/MobileHeaderComponent.vue"
import { getModule } from 'vuex-module-decorators'
import CartModule from './store/CartModule'
import LoginService from './services/LoginService'
import LangModule from './store/LangModule'
import SplashComponent from "@/components/SplashComponent.vue";
import ProductService from "@/services/ProductService";
import SessionModule from "@/store/SessionModule";
import FavouritesModule from "@/store/FavouritesModule";
import Handler from "@/handlers/Handler";
import ConfigResponse from "@/models/responses/ConfigResponse";
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import Config from "@/models/Config";
import ConfigService from "@/services/ConfigService";
import SnackbarModule from "@/store/SnackbarModule";
import LangMixin from "@/mixins/LangMixin";

let components = {
  DrawerComponent, FooterComponent,
  SnackbarComponent, HeaderComponent,
  DialogComponent, LoadingComponent,
  DesktopHeaderComponent, MobileHeaderComponent,
	SplashComponent
}

@Component({components: components})
export default class App extends Mixins(LangMixin) {

  isLoading: boolean = true
  cartModule: CartModule = getModule(CartModule)
  langModule: LangModule = getModule(LangModule)
	sessionModule: SessionModule = getModule(SessionModule)
  configs: SingleItem<Nullable<ConfigResponse>> = { item: null }
  loading: boolean = false

	async mounted() {
    try {
			this.refreshLang()
      await Handler.getItem(this, this.configs, () => ConfigService.getPublicConfigs())
      if (JSON.parse(this.configs.item?.maintenance_mode?.value!!) != true) {
        setTimeout(() => { this.isLoading = false }, 1000)
      } else {
        getModule(SnackbarModule).makeToast("Tienda bajo mantenimiento, por favor intente ingresar mas tarde.")
      }
    } catch (e) {
      getModule(SnackbarModule).makeToast("Ha ocurrido un problema, por favor intentelo mas tarde.")
    }
  }
    
  async created() {
    await LoginService.checkSession(this)
    if (LoginService.isLoged()) {
      await getModule(FavouritesModule).fetchFavoriteProducts()
    }
    this.langModule.loadLanguage()
    this.cartModule.loadCart()

    console.log(this.$vuetify.breakpoint)
  }
}
