


























































import Product from "@/models/Product"
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import CartModule from "@/store/CartModule"
import { getModule } from "vuex-module-decorators"
import CartTool from "@/services/tool/CartTool"
import LoginService from "@/services/LoginService"
import SnackbarModule from "@/store/SnackbarModule"
import SessionModule from "@/store/SessionModule";
import ProductService from "@/services/ProductService";

@Component
export default class ProductListComponent extends Vue {

	@Prop() readonly product!: Product
  @Prop() readonly cartMode!: boolean
	sessionModule: SessionModule = getModule(SessionModule)
  BASE_URL = ConstantTool.BASE_URL
  image: string = ""
	amount: number = 0

	get isLoged(): boolean {
    return LoginService.isLoged()
  }

	cartModule: CartModule = getModule(CartModule)

  addProduct() {
    this.$emit('openProductDialog', this.product)
	}
	
	remove() {
    this.$emit('remove')
 }

	created() {
		console.log(this.product)
		this.refresh()
	}

	errorHandler() {
    this.image = "../assets/ban.svg"
    this.$forceUpdate()
  }

  refresh() {
    this.image = `${this.BASE_URL}/storage/ftp/art_${this.product.connectorId?.toLowerCase()}_1_tmb.jpg`
  }

	addFavourite() {
    if (this.isLoged) {
      getModule(SnackbarModule).makeToast(`El producto ha sido marcado como favorito.`)
    } else {
      this.$router.push("/login")
    }
  }

	addFavoriteProduct(product: Product) {
		ProductService.relateFavoriteProduct(this, this.sessionModule.session.user.id!, product.id!)
	}

  @Watch('product')
  onProductChanged() {
    this.refresh()
	}

	@Watch('product.quantity')
	onQuantityChanged() {
		if (this.cartMode) {
			CartTool.setProductQuantity(this.product.id!, this.product.quantity)
		}
	}
}
