import Product from "@/models/Product";
import StoredProduct from "@/models/StoredProduct";
import CartModule from "@/store/CartModule";
import SnackbarModule from "@/store/SnackbarModule";
import { getModule } from "vuex-module-decorators";

export default class CartTool {

  static cart(): StoredProduct[] { return getModule(CartModule).cart }

  static addProduct(cartModule: CartModule, product: Product) {
    if (product.quantity == 0) { product.quantity = 1 }
    cartModule.setCart(this.cart().filter( e => e.productId !== product.id))
    cartModule.addProduct(product)
    cartModule.setShowMiniCart(true)
    this.refreshCart()
  }

  static setProductQuantity(productId: number, quantity: number) {
    let index = getModule(CartModule).cart.findIndex(product => product.productId == productId)
    getModule(CartModule).cart[index].quantity = quantity
    this.refreshCart()
  }

  static removeProduct(product: Product, products?: Product[]) {
    let index = this.cart().findIndex(e => e.productId == product.id)
    if (index != -1) {
      getModule(CartModule).spliceProduct(index)
      if (products) { products.splice(products.findIndex((e: Product) => e.id == product.id), 1) }
      this.refreshCart()
    }
  }

  static removeAllProducts(){ 
    getModule(CartModule).clearCart()
  }

  static refreshCart() {
    getModule(CartModule).saveCart()
    getModule(CartModule).loadCart()
  }

}
