export default class StringTool {

  private static readonly VALID_EMAIL_ADDRESS_REGEX = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  private static readonly VALID_URL_REGEX = new RegExp(/^(http|https):\/\/[^ "]+$/)
  private static readonly POSTAL_CODE_REGEX:RegExp = /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/

  static validateEmail(email: string): boolean {
      return this.VALID_EMAIL_ADDRESS_REGEX.test(email)
  }

  static validateUrl(url: string): boolean {
      return this.VALID_URL_REGEX.test(url)
  }
  static validatePostalCode(postalcode:string){
      return this.POSTAL_CODE_REGEX.test(postalcode)
  }
  static searchFilter(text: string, search: string): boolean {
      text = text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      let splitFilter: boolean = true
      search.split(" ").forEach(w => { if (!text.includes(w)) { splitFilter = false } })
      return splitFilter
  }

  static replaceDynText(text: string, target: string, addedText: string) {
    return text.replace(target, addedText)
  }

}
