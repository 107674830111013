import axios from "axios";
import ConstantTool from "@/services/tool/ConstantTool";
import JsonTool from "@/services/tool/JsonTool";
import ConfigResponse from "@/models/responses/ConfigResponse";
import Response from "@/models/responses/Response";

export default class ConfigService {
    static async getPublicConfigs(): Promise<Response<ConfigResponse>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/public/config")
            const configs = JsonTool.jsonConvert.deserializeObject(response.data, ConfigResponse)
            return Promise.resolve({ result: configs })
        } catch (e) { return Promise.reject(e) }
    }
}