































import Product from "@/models/Product"
import Dialog from "@/models/vue/Dialog"
import ProductService from "@/services/ProductService"
import CartTool from "@/services/tool/CartTool"
import CartModule from "@/store/CartModule"
import DialogModule from "@/store/DialogModule"
import LangModule from "@/store/LangModule"
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import ProductListComponent from "./ProductListComponent.vue"

@Component({ components: { ProductListComponent } })
export default class CartMenuComponent extends Vue {

  @Prop() readonly value?: boolean
  loading: boolean = false
  products: Product[] = []
  lang: any = getModule(LangModule).lang
  cartModule: CartModule = getModule(CartModule)

  get cart() {
    return this.cartModule.cart
  }

  created() {
    this.refresh()
  }

  goToCart() {
    this.$router.push('/cart').catch(() => {})
  }

  refresh() {
    this.products.splice(0, this.products.length)
    ProductService.getProductsById(this, this.cartModule.cart, this.products)
  }

  clearCart() {
    getModule(DialogModule).showDialog(new Dialog("Aviso", "¿Esta seguro de limpiar el carrito?", () => {
      CartTool.removeAllProducts()
    }))
  }

  removeProduct(product?: Product) {
    if (product) {
      CartTool.removeProduct(product, this.products)
    }
  }

  @Watch('value')
  onValueChanged() {
    this.refresh()
  }

  getTotalTaxBase(): number {
    let totalTaxBase: number = 0
    this.products.forEach(product => {
      totalTaxBase += Number(product.commercialRate) * Number(product.quantity)
    })
    return Number(totalTaxBase.toFixed(2))
  }

}
