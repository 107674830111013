import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("Brand")
export default class Brand {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("code", String, true)
    code?: string = undefined

}
