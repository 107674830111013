import Product from "@/models/Product"
import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import JsonTool from "@/services/tool/JsonTool"
import {Vue} from "vue-property-decorator"
import SnackbarModule from "@/store/SnackbarModule"
import StoredProduct from "@/models/StoredProduct"
import FavouritesModule from "@/store/FavouritesModule"
import axios from "axios";
import Response from "@/models/responses/Response";

export default class ProductService {

    static async getProducts(
        component: Vue, products: Product[], page: number,
        size: number, search: string, categories: number[] | null, hasVideo: boolean | null,
    ) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/public/products", {
                params: {
                    page: page, size: size, search: search, hasVideo: hasVideo,
                    productCategoryIds: categories?.length == 0 ? null : categories!.toString()
                }
            })
            products.splice(0, products.length)
            JsonTool.jsonConvert.deserializeArray(response.data, Product).forEach(p => products.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
            // @ts-ignore
            component.loading = false
        } catch (e) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("No se han podido obtener los productos")
        }
    }

    //Para no filtrar por categorías, pasar un array vacío en los parametros
    static async getFeaturedProducts(
        component: Vue, products: Product[], page: number, size: number, categories: number[] | null, hasVideo: boolean | null,
        orderByActivationDate: boolean | null
    ) {
        (<any>component).loading = true
        try {
            const response: any = await component.axios.get(ConstantTool.BASE_URL + "/public/products", {
                params: {
                    page: page, size: size, featured: "true", hasVideo,
                    productCategoryIds: categories?.length == 0 ? null : categories!.toString(),
                    orderByActivationDate
                },
                headers: {
                    Authorization: getModule(SessionModule).session.token,
                }
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Product)
            products.splice(0, products.length)
            list.forEach(p => products.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
        } catch (error) {
            getModule(SnackbarModule).makeToast("No se han podido obtener los productos")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getProductsByCategoryIds(component: Vue, products: Product[], page: number, size: number, search: string, categories: string) {
        (<any>component).loading = true

        try {
            const response: any = await component.axios.get(ConstantTool.BASE_URL + "/public/products", {
                params: {page: page, size: size, search: search, productCategoryIds: categories}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Product)
            products.splice(0, products.length)
            list.forEach(p => products.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
            // @ts-ignore
            component.loading = false
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("No se han podido obtener los productos")
        }
    }


    static async getProductsById2(storedProducts: StoredProduct[]): Promise<Response<Product[]>> {
        try {
            let products: Product[] = []

            for (let storedProduct of storedProducts) {
                const response = await axios.get(ConstantTool.BASE_URL + "/public/products/" + storedProduct.productId)
                let product: Product = JsonTool.jsonConvert.deserializeObject(response.data, Product)
                product.quantity = storedProduct.quantity!!
                products.push(product)
            }

            return Promise.resolve({ result: products })

        } catch (e) { return Promise.reject(e) }
    }

    static async getProductsById(component: Vue, storedProducts: StoredProduct[], products: Product[], refreshMode?: boolean) {
        if (refreshMode) {
        }
        products.splice(0, products.length)
        if (storedProducts.length > 0) {
            (<any>component).loading = true
            for (let storedProduct of storedProducts) {
                component.axios.get(ConstantTool.BASE_URL + "/public/products/" + storedProduct.productId)
                    .then((response) => {
                        let product: Product = JsonTool.jsonConvert.deserializeObject(response.data, Product)
                        //@ts-ignore
                        product.quantity = storedProduct.quantity
                        products.push(product)
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        (<any>component).loading = false
                    })
            }
        }
    }

    static async getProduct(component: Vue, productId: number) {
        // @ts-ignore
        component.progress = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/public/products/" + productId)
            // @ts-ignore
            component.product = JsonTool.jsonConvert.deserializeObject(response.data, Product)
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se puedo obtener el producto")
        } finally {
            // @ts-ignore
            component.progress = false
        }
    }

    static async getFavoriteProducts(component: Vue, products: Product[], id: number) {
        (<any>component).loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + `/api/users/${id}/favorite-products`, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Product)
            products.splice(0, products.length)
            list.forEach(p => products.push(p))

        } catch (err) {
            getModule(SnackbarModule).makeToast("No se han podido obtener los productos favoritos.")
            console.log(err)
        } finally {
            (<any>component).loading = false
        }
    }


    static async relateFavoriteProduct(component: Vue, id: number, productId: number) {
        try {
            const response = await axios.patch(`${ConstantTool.BASE_URL}/api/users/${id}/favorite-products/${productId}/relate`, null, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            getModule(SnackbarModule).makeToast(`El producto ha sido marcado como favorito.`)
            await getModule(FavouritesModule).fetchFavoriteProducts()
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se ha podido guardar el producto en favoritos.")
            return Promise.reject(e)
        }
    }

    static async unrelateFavoriteProduct(component: Vue, id: number, productId: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + `/api/users/${id}/favorite-products/${productId}/unrelate`, null, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            getModule(SnackbarModule).makeToast(`El producto ha sido desmarcado de los favoritos.`)
            await getModule(FavouritesModule).fetchFavoriteProducts()
        } catch (err) {
            getModule(SnackbarModule).makeToast("No se ha podido desmarcar el producto de los favoritos.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }


    //CHECKPOINT

    static async getPublicProducts(
        page: number, size: number, search: string, categories: Nullable<number[]>, hasVideo: Nullable<boolean>
    ): Promise<Response<Product[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/public/products", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: {
                    page, size, search, hasVideo,
                    productCategoryIds: categories?.length == 0 ? null : categories?.toString(),
                }})
            const products = JsonTool.jsonConvert.deserializeArray(response.data, Product)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: products, xTotalCount  })
        } catch (e) { return Promise.reject(e) }
    }

    static async getProducts2(
        page: number, size: number, search: string, categories: Nullable<number[]>,
        featured: boolean | null, orderByActivationDate: boolean | null, hasVideo: Nullable<boolean>
    ): Promise<Response<Product[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/products", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: {
                    page, size, search, orderByActivationDate, featured, hasVideo,
                    productCategoryIds: categories?.length == 0 ? null : categories?.toString()
                }})
            const products = JsonTool.jsonConvert.deserializeArray(response.data, Product)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: products, xTotalCount  })
        } catch (e) { return Promise.reject(e) }
    }

}
