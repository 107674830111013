import {JsonConvert, OperationMode, ValueCheckingMode} from "json2typescript";
import {PropertyMatchingRule} from "json2typescript/lib/cjs/src/json2typescript/json-convert-enums";
export default class JsonTool {

    // static readonly jsonConvert: JsonConvert = new JsonConvert(OperationMode.ENABLE, ValueCheckingMode.ALLOW_NULL, false, PropertyMatchingRule.CASE_INSENSITIVE)
    static readonly jsonConvert: JsonConvert = new JsonConvert()

    // Object.keys(jsonObject).forEach(key => console.log(key));


}
