



























import { Component, Vue, Watch } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import CountryFlag from 'vue-country-flag'
import LangModule from "@/store/LangModule"
import Language from "@/models/Language";

@Component({ components: { CountryFlag } })
export default class LangMenuComponent extends Vue {

	langModule: any = getModule(LangModule)

	setLanguage(language: Language) {
		this.langModule.setLanguage(language)
		location.reload()
	}

}

