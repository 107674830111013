import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store/index";
import spanish from "@/lang/spanish";
import english from "@/lang/english";
import portuguese from "@/lang/portuguese";
import french from "@/lang/french";

import Language from "@/models/Language";
import italian from "@/lang/italian";

@Module({dynamic: true, store, name: "LangModule"})
export default class LangModule extends VuexModule {

    lang: any = spanish
    language: Language = {name: "ES", flag: "es", file: spanish, value: "SPANISH"}

    languages: Language[] = [
        {name: "Español", flag: "es", file: spanish, value: "SPANISH"},
        {name: "Ingles", flag: "gb", file: english, value: "ENGLISH"},
        {name: "Italiano", flag: "it", file: italian, value: "ITALIAN"},
        {name: "Frances", flag: "fr", file: french, value: "FRENCH"},
        {name: "Portugues", flag: "pt", file: portuguese, value: "PORTUGUESE"}
    ]

    @Mutation
    setLanguage(language: any) {
        this.lang = language.file
        this.language = language
        localStorage.setItem(Language.KEY, JSON.stringify(this.language))
    }

    @Action
    loadLanguage() {
        let languageObject = localStorage.getItem(Language.KEY)
        if (languageObject != null) {
            this.setLanguage(JSON.parse(languageObject))
        }
    }

}