import {JsonObject, JsonProperty} from "json2typescript";
import PanelScreen from "@/models/PanelScreen";

@JsonObject("User")
export default class User {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("email", String, true)
    email?: string = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("lastname", String, true)
    lastname?: string = undefined
    @JsonProperty("commercialName", String, true)
    commercialName?: string = undefined
    @JsonProperty("dni", String, true)
    dni?: string = undefined
    @JsonProperty("active", Boolean, true)
    active?: boolean = undefined
    @JsonProperty("createdBy", User, true)
    createdBy?: User = undefined
    @JsonProperty("panelScreens", [PanelScreen], true)
    panelScreens?: PanelScreen[] = undefined
    @JsonProperty("rateId", Number, true)
    rateId?: number = undefined
}

