import {Module, Mutation, VuexModule} from "vuex-module-decorators"
import store from "@/store"
import ProductCategory from "@/models/ProductCategory";
import ConstantTool from "@/services/tool/ConstantTool";
import axios from "axios";
import JsonTool from "@/services/tool/JsonTool";

@Module({dynamic: true, store, name: "CategoryTreeviewModule"})
export default class CategoryTreeviewModule extends VuexModule {

    productCategories: ProductCategory[] = []
    selectedCategories: number[] = []

    @Mutation
    clear() {
        this.selectedCategories.splice(0, this.selectedCategories.length)
    }

    @Mutation
    setSelectedCategories(categoryIds: number[]) {
        this.selectedCategories = categoryIds
    }

    @Mutation
    async refreshProductCategories() {
        try {
            const response: any = await axios.get(ConstantTool.BASE_URL + "/public/product-categories")
            let list = JsonTool.jsonConvert.deserializeArray(response.data, ProductCategory)
            const root: ProductCategory[] = []
            list.forEach(productCategory => {
                if (productCategory.parent == null) return root.push(productCategory)
                const parentIndex = list.findIndex(el => el.id === productCategory.parent?.id)
                if (!list[parentIndex].children || list[parentIndex].children?.length == 0) { return list[parentIndex].children = [productCategory] }
                list[parentIndex].children?.push(productCategory)
            })
            this.productCategories.splice(0, this.productCategories.length)
            root.forEach(pc => this.productCategories.push(pc))
            console.log(this.productCategories)
        } catch (e) {
            console.log(e)
        }
    }
}